<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">{{ package_id == -1 ? '新增套餐' : '套餐详情' }}</p>
		</div>
		<a-divider style="margin-left: -10px" />

		<a-form-model class="form-edit" :model="form" ref="form" :rules="rules" :labelCol="{ span: 2, offset: 2 }"
			:wrapperCol="{ span: 15 }" style="width: 1000px">
			<a-form-model-item label="套餐名称" prop="name">
				<a-input v-model="form.name" placeholder="请输入" />
			</a-form-model-item>
			<a-form-model-item label="售价" prop="price">
				<a-input suffix="元" placeholder="请输入" v-model="form.sale_money" />
			</a-form-model-item>
			<a-form-model-item label="服务项目" prop="name" placeholder="请输入" v-if="services.length != 0">
				<div style="display: flex; align-items: center;flex-direction: column; margin: 10px; margin-left: -180px;">
					<div style="position: relative; " v-for="(item, index) in services" :key="index">
						<div style="display: flex; align-items: center;">
							<a-select placeholder="请选择服务" style="width: 300px; margin-right: 20px;"
								v-model="services[index].service_id">
								<a-select-option :value="itemson.service_id" v-for="itemson in service_list"
									:key="itemson.service_id">
									{{ itemson.name }}
								</a-select-option>
							</a-select>
							<a-input suffix="次" style="width: 75px; margin-right: 20px;" v-model="item.number" />
							<img @click="jfDel(item, index)" src="@/assets/shanchu.png" alt=""
								style="width: 20px; height: 20px;" v-if="index != 0 && package_id == -1">

							<div alt="" style="width: 20px; height: 20px;" v-if="index == 0 || package_id != -1">
							</div>
						</div>
					</div>
				</div>
			</a-form-model-item>
			<div style=" margin-top: 20px;">
				<a-button @click="jfAdd" block style="width: 300px; margin-left: 165px;">+添加服务项</a-button>
			</div>
			<div style="display: flex; justify-content: flex-end; margin-top: 20px;">
				<a-button @click="cancel" style="margin-right: 12px;">取消</a-button>
				<a-button type="primary" @click="submitForm" v-if="this.package_id == -1">保存</a-button>
			</div>
		</a-form-model>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from '@/utils/uploader'
import { toolbar, fontSize } from '@/utils/toolbar'
export default {
	data() {
		return {
			form: {
			},
			services: [],
			rules: {
				value: [{ required: true, message: '请输入标题' }],
			},
			showJifei: false,
			service_list: [],
			initService: {
				'service_id': "",
				'number': null,
			},
			package_id: null
		}
	},
	created() {
		this.getServiceList()
		this.package_id = this.$route.query.key
		// console.log("key", this.key)
		if (this.package_id != -1) {
			this.getInfo(this.package_id)
		}
		// console.log("key", this.form)
	},
	methods: {
		jfDel(item, index) {
			this.services.splice(index, 1);
		},
		jfAdd() {
			if (this.package_id != -1) {
				this.$message.warn("已添加套餐无法修改！")
				return
			}
			// this.services[this.services.length] = this.initService
			// console.log(this.initService)
			this.services.push({
				'service_id': "",
				'number': null,
			})
			console.log("servcies:" + this.services.map(obj => JSON.stringify(obj)))
		},
		getServiceList() {
			this.$post(domain + '/admin/v1/service/listNoPage').then(res => {
				if (res.code === 0) {
					this.service_list = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			this.$refs.form.validate((valid) => {
				let str = ""
				if (this.package_id == -1) {
					str = "add"
				} else {
					this.form.package_id = this.package_id
					str = "update"
				}
				if (valid) {
					this.form.services = this.services
					this.$post(domain + '/admin/v1/package/' + str,
						this.form
					).then((res) => {
						if (res.code === 0) {
							this.$message.success('保存成功')
							this.$router.back()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
		back() {
			this.$router.back()
		},
		cancel() { this.$router.back() },
		getInfo(key) {
			this.$post(domain + '/admin/v1/package/info', { "package_id": key }).then(res => {
				if (res.code === 0) {
					this.form = res.data
					this.services = res.data.services
				} else {
					this.$message.error(res.msg)
				}
			})
		}
	},
}
</script>

<style lang="less" scoped>
.row-two-item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.item {
		width: 277px;
	}

	.line-two {
		height: 1px;
		background-color: #979797;
		width: 38px;
	}
}

.icon_container {
	position: absolute;
	top: 10px;
	right: 300px;

	&>img {
		width: 20px;
		height: 20px;
		margin-left: 6px;
		cursor: pointer;
	}
}

.upload-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img-upload {
	width: 86px;
	height: 86px;
}

#editor {
	height: 560px;
	border: 1px solid #d9d9d9;
}
</style>
<style lang="less">
.form-edit {
	.upload-card {
		.ant-upload.ant-upload-select-picture-card {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
</style>
